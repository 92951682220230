import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';

const StyledDiv = styled.div`
  padding-left: 15px;
  font-size: 20px;
`;

export default function SearchStatus({ loading, loadingTotal, total, searched }) {
  const exportDisabled = loading || loadingTotal;
  const formatedTotal = total || 0;

  return <StyledDiv>
    {exportDisabled ?
      <>Calculating results. Please wait to download...</>
      : searched ?
        total > 0 ?
          <>
            <b>{formatedTotal.toLocaleString()}</b> results found. Click download (<DownloadOutlined />) to export and choose quantity
          </>
          :
          <>
            It looks like there are no matches for your search
          </>
        :
        <>Please click <strong>search (<SearchOutlined />)</strong> to apply the new filters</>
    }
  </StyledDiv>;
}

SearchStatus.propTypes = {
  loading: PropTypes.bool,
  loadingTotal: PropTypes.bool,
  total: PropTypes.number,
  searched: PropTypes.bool,
};
