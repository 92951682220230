import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import queryString from 'query-string';
import Moment from 'react-moment';
import { Card, Button, Space, Col, Row, Statistic, Typography, Checkbox, List, InputNumber } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import jwt_decode from 'jwt-decode';
import config from 'react-global-configuration';
import qs from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';

import { removeKeys } from '../../helpers/common';
import ResultsTypeSelect from './ResultsTypeSelect';

const { Meta } = Card;
const { Text } = Typography;
const MIN_CHUNK_SIZE = 50000;
const MAX_CHUNK_SIZE = 500000;

export default function CheckoutResults() {
  const location = useLocation();
  const navigate = useNavigate();
  const { search } = location;
  const { token, fileType, quantity, units = 'rows' } = queryString.parse(search);
  const payload = jwt_decode(token);
  const { __expire_at, product_name, search_params } = payload || {};
  const expired = __expire_at ? parseInt(Date.now()/1000) > __expire_at : false;

  const [type, setType] = useState(fileType);
  const [clicked, setClicked] = useState(false);
  const [inChunk, setInChunk] = useState(false);
  const [chunkSize, setChunkSize] = useState(quantity > MAX_CHUNK_SIZE * 3 ? MAX_CHUNK_SIZE : MIN_CHUNK_SIZE);

  const splitable = quantity > MIN_CHUNK_SIZE * 1.5;
  const chunks = [];
  if (inChunk) {
    for (let i = 0; i < quantity; i += chunkSize) {
      chunks.push(i);
    }
  }

  useEffect(() => {
    if (!token) {
      notification.error({ message: 'Empty token' });
    }
  }, []);

  function downloadCheckoutResults(chunkSkip, chunkQuantity) {
    if (chunkQuantity === quantity) setClicked(true);

    notification.success({
      message: `Downloading ${type.toUpperCase()}`,
      description: <>Downloading big files can take a while. Please don&apos;t close your browser while downloading.</>,
      icon: <DownloadOutlined style={{ color: '#108ee9' }} />,
      duration: 8, style: { width: 600, marginLeft: 335 - 600}
    });

    const url = `${window.location.protocol}//${config.get('apiDomain')}/download-checkout-results?token=${token}&fileType=${type}&productName=${product_name}&chunkSkip=${chunkSkip}&chunkQuantity=${chunkQuantity}`;

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `results_${chunkSkip+1}-${chunkQuantity}.${type}`);
    document.body.appendChild(link);
    link.click();

    if (chunkQuantity === quantity) setTimeout(() => setClicked(false), 30000);
  }

  function onBackToSearch() {
    const productUrl = product_name.toLowerCase() === 'places' ? 'local-businesses' : 'businesses';
    const updatedParams = removeKeys(search_params, 'export');
    navigate(`/${productUrl}?${qs.stringify(updatedParams)}`, { replace: true });
  }

  function onSetType(value) {
    setType(value);
    setClicked(false);
  }

  return (
    <Card>
      <Meta
        title='Downloading Results'
      />
      <br/><br/>
      <Row gutter={16}>
        <Col span={8}>
          <Statistic title={`Amount of ${units}`} value={quantity} />
        </Col>
        <Col span={8}>
          <Statistic
            title='File type'
            value={type}
            formatter={(value) => <ResultsTypeSelect value={value} onChange={onSetType} style={{ width: 110 }} bordered={false}/>}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title='Expire at'
            value={__expire_at}
            formatter={(value) => <Text type={expired ? 'danger' : null}>
              <Moment unix local format='YYYY-MM-DD HH:mm'>{value}</Moment>
              {expired && ' (Expired)'}
            </Text>}
          />
        </Col>
      </Row>

      <br/><br/>
      <Space>
        <Button onClick={onBackToSearch}>Back To Search</Button>
        <Button
          disabled={expired || clicked || inChunk}
          type='primary'
          title={`Download Results in ${type} format`}
          icon={<DownloadOutlined/>}
          onClick={() => downloadCheckoutResults(0, quantity)}
        >Download Results ({type.toUpperCase()})</Button>
        {splitable && <Checkbox
          checked={inChunk}
          onChange={() => setInChunk(!inChunk)}
        >Download in chunks</Checkbox>}
      </Space>

      {inChunk && <>
        <br/><br/>
        <Space>
          Maximum records per single file:
          <InputNumber
            min={MIN_CHUNK_SIZE}
            max={MAX_CHUNK_SIZE}
            step={50000}
            value={chunkSize}
            onChange={(v) => setChunkSize(v || MIN_CHUNK_SIZE)}
          />
        </Space>
        <br/><br/>
        <List
          dataSource={chunks}
          renderItem={(start) => (
            <List.Item
              actions={[
                <Button
                  key='download'
                  type='primary'
                  disabled={expired}
                  title={`Download Results in ${type} format`}
                  icon={<DownloadOutlined/>}
                  onClick={() => downloadCheckoutResults(start, chunkSize)}
                >Download {(Math.min(start + chunkSize, quantity) - start).toLocaleString()} Records ({type.toUpperCase()})</Button>
              ]}
            >
              Rows: {(start + 1).toLocaleString()} - {(Math.min(start + chunkSize, quantity)).toLocaleString()}
            </List.Item>
          )}
        />
      </>}
    </Card>
  );
}
