import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Tooltip } from 'antd';
import styled from 'styled-components';

const StyledTag = styled(Tag)`
  height: fit-content;
  margin-left: 6px;

  @media (max-width: 900px) {
    margin-left: 0px;
    width: fit-content;
  }
`;

export default function Categories({ value = [], showFirst = 1 }) {
  if (!value) value = [];
  const arrayValue = typeof value === 'string' ? [value] : value;

  const categories = arrayValue.slice(0, showFirst);
  const hasMore = arrayValue.length - categories.length;

  return <Tooltip title={arrayValue.join(', ')}>
    <StyledTag>{arrayValue.slice(0, showFirst).join(', ')}{!!hasMore && '...'}</StyledTag>
  </Tooltip>;
}

Categories.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  showFirst: PropTypes.number,
};
