import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { notification, message } from 'antd';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import { fetchProfile, removeProfile, makePaymentNMI } from '../../redux/actions/profileActions';
import Href from '../../shared/Components/Href';
import ProfileDangerousActions from './ProfileDangerousActions';
import UpcomingInvoice from './UpcomingInvoice';
import PromotionCode from './PromotionCode';
import ReferralProgram from './ReferralProgram';
import SupportBlock from './SupportBlock';
import EmailAddress from './EmailAddress';
import Balance from './Balance';
import APIToken from './APIToken';

function Space() { return (<><br/><br/><br/></>); }

export default function Profile({ authForwarded }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { search = {} } = location;
  const { profile: authProfile } = authForwarded || {};
  const { emailVerified, email, roles = [], provider, userId } = authProfile || {};
  const isSupport = roles.includes('support');
  const isTenant = roles.includes('tenant');
  const { secondary_tab, deposite, deposited } = queryString.parse(search);
  const isSecondaryTab = secondary_tab && secondary_tab === 'true';

  const profile = useSelector(state => state.profileReducer.profile);
  const loading = useSelector(state => state.profileReducer.loading);
  const dispatch = useDispatch();

  const { account_status, api_token, coupon = {}, upcoming_invoice = {}, referred } = profile;
  const { name: couponName } = coupon;
  const accountNotValid = account_status !== 'valid';

  const [passwordChangeRequested, setPasswordChangeRequested] = useState(false);

  useEffect(() => {
    document.title = 'Profile';

    if (deposite) {
      handleOnMakePayment(parseInt(deposite), isSecondaryTab);
    } else if (deposited === 'true') {
      onFetchProfile();
      if (isSecondaryTab) {
        message.success('Return to the previous tab', 30);
        setTimeout(() => window.close(), 1000);
      }
    }
    else {
      onFetchProfile();
    }
  }, []);

  async function onPaymentMade() {
    await onFetchProfile();
    if (isSecondaryTab) {
      setTimeout(() => window.close(), 1000);
    }

  }

  async function handleOnMakePayment(amount, secondaryTab) {
    onMakePayment(amount, secondaryTab);
  }

  function onMakePayment(amount, secondaryTab = false) {
    dispatch(makePaymentNMI(amount, secondaryTab));
  }

  function onFetchProfile() {
    return dispatch(fetchProfile());
  }

  async function onPasswordChangeRequest() {
    setPasswordChangeRequested(true);
    const cb = function () {
      notification.success({ message: 'Please, check your email and follow the link to change the password', duration: 7 });
    };
    await authForwarded.requestPasswordChange(cb);
  }

  function onProfileRemove() {
    dispatch(removeProfile(navigate));
  }

  function onEmailChanged() {
    authForwarded.logout();
  }

  return <>
    {!isTenant && <Balance loading={loading} profile={profile} onPaymentMade={onPaymentMade} emailVerified={emailVerified}/>}

    <h3>API Token</h3>
    <p>
      Use services from your code. Check
      <Href external href='/api-docs'> API docs </Href>for more information.
    </p>
    <APIToken loading={loading} token={api_token} accountNotValid={accountNotValid}/>
    {account_status === 'no_default_source' && <>
      <br/><br/>
      <button
        type='button'
        className='link-button error-link-button'
      >* Please add credits to your account balance before using the API.</button>
    </>}
    <Space />

    {!isEmpty(upcoming_invoice) && <>
      <h3>Upcoming Invoice</h3>
      <UpcomingInvoice loading={loading} value={upcoming_invoice} couponName={couponName} isSupport={isSupport} />
      <Space />
    </>}

    <h3>Referral Program</h3>
    <p>
      Refer a friend and start receiving <strong>25%</strong> of your
      referral payments to your account balance.
    </p>
    <ReferralProgram userId={userId} disabled={loading} referred={referred} />
    <Space />

    <h3>Promotion Code</h3>
    <PromotionCode existingCouponName={couponName} />
    <Space />

    <h3>Email Address</h3>
    <EmailAddress
      value={email}
      verified={emailVerified}
    />
    <Space />

    {isSupport && <>
      <SupportBlock profile={authProfile} loading={loading} auth={authForwarded} />
      <Space />
    </>}

    <ProfileDangerousActions
      loading={loading}
      passwordChangeRequested={passwordChangeRequested}
      onPasswordChangeRequest={onPasswordChangeRequest}
      onEmailChanged={onEmailChanged}
      onRemove={onProfileRemove}
      authProvider={provider}
    />
  </>;
}

Profile.propTypes = {
  authForwarded: PropTypes.object.isRequired,
  auth: PropTypes.object,
};