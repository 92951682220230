export const regions = [
  { name: 'Afghanistan', value: 'AF', alpha3: 'AFG' },
  { name: 'Alan Islands', value: 'AX', alpha3: 'ALA' },
  { name: 'Albania', value: 'AL', alpha3: 'ALB' },
  { name: 'Algeria', value: 'DZ', alpha3: 'DZA' },
  { name: 'American Samoa', value: 'AS', alpha3: 'ASM' },
  { name: 'Andorra', value: 'AD', alpha3: 'AND' },
  { name: 'Angola', value: 'AO', alpha3: 'AGO' },
  { name: 'Anguilla', value: 'AI', alpha3: 'AIA' },
  { name: 'Antarctica', value: 'AQ', alpha3: 'ATA' },
  { name: 'Antigua & Barbuda', value: 'AG', alpha3: 'ATG' },
  { name: 'Argentina', value: 'AR', alpha3: 'ARG' },
  { name: 'Armenia', value: 'AM', alpha3: 'ARM' },
  { name: 'Aruba', value: 'AW', alpha3: 'ABW' },
  { name: 'Australia', value: 'AU', alpha3: 'AUS' },
  { name: 'Austria', value: 'AT', alpha3: 'AUT' },
  { name: 'Azerbaijan', value: 'AZ', alpha3: 'AZE' },
  { name: 'Bahamas', value: 'BS', alpha3: 'BHS' },
  { name: 'Bahrain', value: 'BH', alpha3: 'BHR' },
  { name: 'Bangladesh', value: 'BD', alpha3: 'BGD' },
  { name: 'Barbados', value: 'BB', alpha3: 'BRB' },
  { name: 'Belarus', value: 'BY', alpha3: 'BLR' },
  { name: 'Belgium', value: 'BE', alpha3: 'BEL' },
  { name: 'Belize', value: 'BZ', alpha3: 'BLZ' },
  { name: 'Benin', value: 'BJ', alpha3: 'BEN' },
  { name: 'Bermuda', value: 'BM', alpha3: 'BMU' },
  { name: 'Bhutan', value: 'BT', alpha3: 'BTN' },
  { name: 'Bolivia', value: 'BO', alpha3: 'BOL' },
  { name: 'Bonaire', value: 'BQ', alpha3: 'BES' },
  { name: 'Bosnia & Herzegovina', value: 'BA', alpha3: 'BIH' },
  { name: 'Botswana', value: 'BW', alpha3: 'BWA' },
  { name: 'Bouvet Island', value: 'BV', alpha3: 'BVT' },
  { name: 'Brazil', value: 'BR', alpha3: 'BRA' },
  { name: 'British Virgin Islands', value: 'VG', alpha3: 'VGB' },
  { name: 'Brunei', value: 'BN', alpha3: 'BRN' },
  { name: 'Bulgaria', value: 'BG', alpha3: 'BGR' },
  { name: 'Burkina Faso', value: 'BF', alpha3: 'BFA' },
  { name: 'Burundi', value: 'BI', alpha3: 'BDI' },
  { name: 'Cambodia', value: 'KH', alpha3: 'KHM' },
  { name: 'Cameroon', value: 'CM', alpha3: 'CMR' },
  { name: 'Canada', value: 'CA', alpha3: 'CAN' },
  { name: 'Cape Verde', value: 'CV', alpha3: 'CPV' },
  { name: 'Cayman Islands', value: 'KY', alpha3: 'CYM' },
  { name: 'Central African Republic', value: 'CF', alpha3: 'CAF' },
  { name: 'Chad', value: 'TD', alpha3: 'TCD' },
  { name: 'Chile', value: 'CL', alpha3: 'CHL' },
  { name: 'China', value: 'CN', alpha3: 'CHN' },
  { name: 'Colombia', value: 'CO', alpha3: 'COL' },
  { name: 'Comoros', value: 'KM', alpha3: 'COM' },
  { name: 'Congo - Brazzaville', value: 'CG', alpha3: 'COG' },
  { name: 'Congo - Kinshasa', value: 'CD', alpha3: 'COD' },
  { name: 'Cook Islands', value: 'CK', alpha3: 'COK' },
  { name: 'Costa Rica', value: 'CR', alpha3: 'CRI' },
  { name: 'Côte d’Ivoire', value: 'CI', alpha3: 'CIV' },
  { name: 'Croatia', value: 'HR', alpha3: 'HRV' },
  { name: 'Cuba', value: 'CU', alpha3: 'CUB' },
  { name: 'Curacao', value: 'CW', alpha3: 'CUW' },
  { name: 'Cyprus', value: 'CY', alpha3: 'CYP' },
  { name: 'Czechia', value: 'CZ', alpha3: 'CZE' },
  { name: 'Denmark', value: 'DK', alpha3: 'DNK' },
  { name: 'Djibouti', value: 'DJ', alpha3: 'DJI' },
  { name: 'Dominica', value: 'DM', alpha3: 'DMA' },
  { name: 'Dominican Republic', value: 'DO', alpha3: 'DOM' },
  { name: 'Ecuador', value: 'EC', alpha3: 'ECU' },
  { name: 'Egypt', value: 'EG', alpha3: 'EGY' },
  { name: 'El Salvador', value: 'SV', alpha3: 'SLV' },
  { name: 'Equatorial Guinea', value: 'GQ', alpha3: 'GNQ' },
  { name: 'Eritrea', value: 'ER', alpha3: 'ERI' },
  { name: 'Estonia', value: 'EE', alpha3: 'EST' },
  { name: 'Eswatini', value: 'SZ', alpha3: 'SWZ' },
  { name: 'Ethiopia', value: 'ET', alpha3: 'ETH' },
  { name: 'Falkland Islands', value: 'FK', alpha3: 'FLK' },
  { name: 'Faroe Islands', value: 'FO', alpha3: 'FRO' },
  { name: 'Fiji', value: 'FJ', alpha3: 'FJI' },
  { name: 'Finland', value: 'FI', alpha3: 'FIN' },
  { name: 'France', value: 'FR', alpha3: 'FRA' },
  { name: 'French Guiana', value: 'GF', alpha3: 'GUF' },
  { name: 'French Polynesia', value: 'PF', alpha3: 'PYF' },
  { name: 'French Southern Territories', value: 'TF', alpha3: 'ATF' },
  { name: 'Gabon', value: 'GA', alpha3: 'GAB' },
  { name: 'Gambia', value: 'GM', alpha3: 'GMB' },
  { name: 'Georgia', value: 'GE', alpha3: 'GEO' },
  { name: 'Germany', value: 'DE', alpha3: 'DEU' },
  { name: 'Ghana', value: 'GH', alpha3: 'GHA' },
  { name: 'Gibraltar', value: 'GI', alpha3: 'GIB' },
  { name: 'Greece', value: 'GR', alpha3: 'GRC' },
  { name: 'Greenland', value: 'GL', alpha3: 'GRL' },
  { name: 'Grenada', value: 'GD', alpha3: 'GRD' },
  { name: 'Guadeloupe', value: 'GP', alpha3: 'GLP' },
  { name: 'Guam', value: 'GU', alpha3: 'GUM' },
  { name: 'Guatemala', value: 'GT', alpha3: 'GTM' },
  { name: 'Guernsey', value: 'GG', alpha3: 'GGY' },
  { name: 'Guinea', value: 'GN', alpha3: 'GIN' },
  { name: 'Guinea-Bissau', value: 'GW', alpha3: 'GNB' },
  { name: 'Guyana', value: 'GY', alpha3: 'GUY' },
  { name: 'Haiti', value: 'HT', alpha3: 'HTI' },
  { name: 'Heard Island & McDonald Islands', value: 'HM', alpha3: 'HMD' },
  { name: 'Honduras', value: 'HN', alpha3: 'HND' },
  { name: 'Hong Kong', value: 'HK', alpha3: 'HKG' },
  { name: 'Hungary', value: 'HU', alpha3: 'HUN' },
  { name: 'Iceland', value: 'IS', alpha3: 'ISL' },
  { name: 'India', value: 'IN', alpha3: 'IND' },
  { name: 'Indonesia', value: 'ID', alpha3: 'IDN' },
  { name: 'Iran', value: 'IR', alpha3: 'IRN' },
  { name: 'Iraq', value: 'IQ', alpha3: 'IRQ' },
  { name: 'Ireland', value: 'IE', alpha3: 'IRL' },
  { name: 'Isle of Man', value: 'IM', alpha3: 'IMN' },
  { name: 'Israel', value: 'IL', alpha3: 'ISR' },
  { name: 'Italy', value: 'IT', alpha3: 'ITA' },
  { name: 'Jamaica', value: 'JM', alpha3: 'JAM' },
  { name: 'Japan', value: 'JP', alpha3: 'JPN' },
  { name: 'Jersey', value: 'JE', alpha3: 'JEY' },
  { name: 'Jordan', value: 'JO', alpha3: 'JOR' },
  { name: 'Kazakhstan', value: 'KZ', alpha3: 'KAZ' },
  { name: 'Kenya', value: 'KE', alpha3: 'KEN' },
  { name: 'Kiribati', value: 'KI', alpha3: 'KIR' },
  { name: 'Kosovo', value: 'XK', alpha3: 'XKX' },
  { name: 'Kuwait', value: 'KW', alpha3: 'KWT' },
  { name: 'Kyrgyzstan', value: 'KG', alpha3: 'KGZ' },
  { name: 'Laos', value: 'LA', alpha3: 'LAO' },
  { name: 'Latvia', value: 'LV', alpha3: 'LVA' },
  { name: 'Lebanon', value: 'LB', alpha3: 'LBN' },
  { name: 'Lesotho', value: 'LS', alpha3: 'LSO' },
  { name: 'Liberia', value: 'LR', alpha3: 'LBR' },
  { name: 'Libya', value: 'LY', alpha3: 'LBY' },
  { name: 'Liechtenstein', value: 'LI', alpha3: 'LIE' },
  { name: 'Lithuania', value: 'LT', alpha3: 'LTU' },
  { name: 'Luxembourg', value: 'LU', alpha3: 'LUX' },
  { name: 'Macao', value: 'MO', alpha3: 'MAC' },
  { name: 'Madagascar', value: 'MG', alpha3: 'MDG' },
  { name: 'Malawi', value: 'MW', alpha3: 'MWI' },
  { name: 'Malaysia', value: 'MY', alpha3: 'MYS' },
  { name: 'Maldives', value: 'MV', alpha3: 'MDV' },
  { name: 'Mali', value: 'ML', alpha3: 'MLI' },
  { name: 'Malta', value: 'MT', alpha3: 'MLT' },
  { name: 'Marshall Islands', value: 'MH', alpha3: 'MHL' },
  { name: 'Martinique', value: 'MQ', alpha3: 'MTQ' },
  { name: 'Mauritania', value: 'MR', alpha3: 'MRT' },
  { name: 'Mauritius', value: 'MU', alpha3: 'MUS' },
  { name: 'Mayotte', value: 'YT', alpha3: 'MYT' },
  { name: 'Mexico', value: 'MX', alpha3: 'MEX' },
  { name: 'Micronesia', value: 'FM', alpha3: 'FSM' },
  { name: 'Moldova', value: 'MD', alpha3: 'MDA' },
  { name: 'Monaco', value: 'MC', alpha3: 'MCO' },
  { name: 'Mongolia', value: 'MN', alpha3: 'MNG' },
  { name: 'Montenegro', value: 'ME', alpha3: 'MNE' },
  { name: 'Montserrat', value: 'MS', alpha3: 'MSR' },
  { name: 'Morocco', value: 'MA', alpha3: 'MAR' },
  { name: 'Mozambique', value: 'MZ', alpha3: 'MOZ' },
  { name: 'Myanmar', value: 'MM', alpha3: 'MMR' },
  { name: 'Namibia', value: 'NA', alpha3: 'NAM' },
  { name: 'Nauru', value: 'NR', alpha3: 'NRU' },
  { name: 'Nepal', value: 'NP', alpha3: 'NPL' },
  { name: 'Netherlands', value: 'NL', alpha3: 'NLD' },
  { name: 'New Caledonia', value: 'NC', alpha3: 'NCL' },
  { name: 'New Zealand', value: 'NZ', alpha3: 'NZL' },
  { name: 'Nicaragua', value: 'NI', alpha3: 'NIC' },
  { name: 'Niger', value: 'NE', alpha3: 'NER' },
  { name: 'Nigeria', value: 'NG', alpha3: 'NGA' },
  { name: 'Niue', value: 'NU', alpha3: 'NIU' },
  { name: 'Norfolk Island', value: 'NF', alpha3: 'NFK' },
  { name: 'North Korea', value: 'KP', alpha3: 'PRK' },
  { name: 'North Macedonia', value: 'MK', alpha3: 'MKD' },
  { name: 'Northern Mariana Islands', value: 'MP', alpha3: 'MNP' },
  { name: 'Norway', value: 'NO', alpha3: 'NOR' },
  { name: 'Oman', value: 'OM', alpha3: 'OMN' },
  { name: 'Pakistan', value: 'PK', alpha3: 'PAK' },
  { name: 'Palau', value: 'PW', alpha3: 'PLW' },
  { name: 'Palestine', value: 'PS', alpha3: 'PSE' },
  { name: 'Panama', value: 'PA', alpha3: 'PAN' },
  { name: 'Papua New Guinea', value: 'PG', alpha3: 'PNG' },
  { name: 'Paraguay', value: 'PY', alpha3: 'PRY' },
  { name: 'Peru', value: 'PE', alpha3: 'PER' },
  { name: 'Philippines', value: 'PH', alpha3: 'PHL' },
  { name: 'Pitcairn Islands', value: 'PN', alpha3: 'PCN' },
  { name: 'Poland', value: 'PL', alpha3: 'POL' },
  { name: 'Portugal', value: 'PT', alpha3: 'PRT' },
  { name: 'Puerto Rico', value: 'PR', alpha3: 'PRI' },
  { name: 'Qatar', value: 'QA', alpha3: 'QAT' },
  { name: 'Reunion', value: 'RE', alpha3: 'REU' },
  { name: 'Romania', value: 'RO', alpha3: 'ROU' },
  { name: 'Russia', value: 'RU', alpha3: 'RUS' },
  { name: 'Rwanda', value: 'RW', alpha3: 'RWA' },
  { name: 'Saint Barthélemy', value: 'BL', alpha3: 'BLM' },
  { name: 'Saint Helena', value: 'SH', alpha3: 'SHN' },
  { name: 'Saint Kitts & Nevis', value: 'KN', alpha3: 'KNA' },
  { name: 'Saint Lucia', value: 'LC', alpha3: 'LCA' },
  { name: 'Saint Martin', value: 'MF', alpha3: 'MAF' },
  { name: 'Saint Pierre & Miquelon', value: 'PM', alpha3: 'SPM' },
  { name: 'Saint Vincent & Grenadines', value: 'VC', alpha3: 'VCT' },
  { name: 'Samoa', value: 'WS', alpha3: 'WSM' },
  { name: 'San Marino', value: 'SM', alpha3: 'SMR' },
  { name: 'São Tomé & Príncipe', value: 'ST', alpha3: 'STP' },
  { name: 'Saudi Arabia', value: 'SA', alpha3: 'SAU' },
  { name: 'Senegal', value: 'SN', alpha3: 'SEN' },
  { name: 'Serbia', value: 'RS', alpha3: 'SRB' },
  { name: 'Seychelles', value: 'SC', alpha3: 'SYC' },
  { name: 'Sierra Leone', value: 'SL', alpha3: 'SLE' },
  { name: 'Singapore', value: 'SG', alpha3: 'SGP' },
  { name: 'Sint Maarten', value: 'SX', alpha3: 'SXM' },
  { name: 'Slovakia', value: 'SK', alpha3: 'SVK' },
  { name: 'Slovenia', value: 'SI', alpha3: 'SVN' },
  { name: 'Solomon Islands', value: 'SB', alpha3: 'SLB' },
  { name: 'Somalia', value: 'SO', alpha3: 'SOM' },
  { name: 'South Africa', value: 'ZA', alpha3: 'ZAF' },
  { name: 'South Georgia & South Sandwich Islands', value: 'GS', alpha3: 'SGS' },
  { name: 'South Korea', value: 'KR', alpha3: 'KOR' },
  { name: 'South Sudan', value: 'SS', alpha3: 'SSD' },
  { name: 'Spain', value: 'ES', alpha3: 'ESP' },
  { name: 'Sri Lanka', value: 'LK', alpha3: 'LKA' },
  { name: 'Sudan', value: 'SD', alpha3: 'SDN' },
  { name: 'Suriname', value: 'SR', alpha3: 'SUR' },
  { name: 'Svalbard & Jan Mayen', value: 'SJ', alpha3: 'SJM' },
  { name: 'Sweden', value: 'SE', alpha3: 'SWE' },
  { name: 'Switzerland', value: 'CH', alpha3: 'CHE' },
  { name: 'Syria', value: 'SY', alpha3: 'SYR' },
  { name: 'Taiwan', value: 'TW', alpha3: 'TWN' },
  { name: 'Tajikistan', value: 'TJ', alpha3: 'TJK' },
  { name: 'Tanzania', value: 'TZ', alpha3: 'TZA' },
  { name: 'Thailand', value: 'TH', alpha3: 'THA' },
  { name: 'Timor-Leste', value: 'TL', alpha3: 'TLS' },
  { name: 'Togo', value: 'TG', alpha3: 'TGO' },
  { name: 'Tokelau', value: 'TK', alpha3: 'TKL' },
  { name: 'Tonga', value: 'TO', alpha3: 'TON' },
  { name: 'Trinidad & Tobago', value: 'TT', alpha3: 'TTO' },
  { name: 'Tunisia', value: 'TN', alpha3: 'TUN' },
  { name: 'Turkey', value: 'TR', alpha3: 'TUR' },
  { name: 'Turkmenistan', value: 'TM', alpha3: 'TKM' },
  { name: 'Turks & Caicos Islands', value: 'TC', alpha3: 'TCA' },
  { name: 'Tuvalu', value: 'TV', alpha3: 'TUV' },
  { name: 'Uganda', value: 'UG', alpha3: 'UGA' },
  { name: 'Ukraine', value: 'UA', alpha3: 'UKR' },
  { name: 'United Arab Emirates', value: 'AE', alpha3: 'ARE' },
  { name: 'United Kingdom', value: 'GB', alpha3: 'GBR' },
  { name: 'United States', value: 'US', alpha3: 'USA' },
  { name: 'Uruguay', value: 'UY', alpha3: 'URY' },
  { name: 'Uzbekistan', value: 'UZ', alpha3: 'UZB' },
  { name: 'Vanuatu', value: 'VU', alpha3: 'VUT' },
  { name: 'Vatican City', value: 'VA', alpha3: 'VAT' },
  { name: 'Venezuela', value: 'VE', alpha3: 'VEN' },
  { name: 'Vietnam', value: 'VN', alpha3: 'VNM' },
  { name: 'Wallis & Futuna', value: 'WF', alpha3: 'WLF' },
  { name: 'Western Sahara', value: 'EH', alpha3: 'ESH' },
  { name: 'Yemen', value: 'YE', alpha3: 'YEM' },
  { name: 'Zambia', value: 'ZM', alpha3: 'ZMB' },
  { name: 'Zimbabwe', value: 'ZW', alpha3: 'ZWE' }
];
