import JSZip from 'jszip';
import xml2js from 'xml2js';
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { MapContainer, FeatureGroup, Marker, Popup, TileLayer, ZoomControl, useMap } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Polygon, Circle } from 'react-leaflet';

import PlaceTitle from '../Place/PlaceTitle';
import { arraysAreEqual } from '../../../utils/helper';

import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
let DefaultIcon = L.icon({ iconUrl: icon, shadowUrl: iconShadow });
L.Marker.prototype.options.icon = DefaultIcon;

const DEFAULT_ZOOM = 6;
function Map({ places = [], geoFilters, onGeoFiltersChange, selectedPlace = 0, description }) {
  const [map, setMap] = useState(null);
  const [kmzCoordinates, setKmzCoordinates] = useState([]);
  const [loading, setLoading] = useState(null);

  const navigated = places.length > 0;
  const selectedPlaceValue = places[selectedPlace];
  const center = navigated ? [selectedPlaceValue.latitude, selectedPlaceValue.longitude] : [40.7055537,	-74.0134436];

  let editableFGref = null;

  useEffect(() => {
    if (map) {
      map.setView(center, DEFAULT_ZOOM);
    }

  }, [places]);

  useEffect(() => {
    if (map) {
      map.setView(center, DEFAULT_ZOOM);
    }

  }, [selectedPlace]);

  useEffect(() => {
    if (!geoFilters || geoFilters.length < 1) {
      removeAllLayers();
    }

  }, [geoFilters]);

  async function parseKmzFile(file) {
    const zip = await JSZip.loadAsync(file);
    const kmlFile = zip.file(/.kml$/i)[0]; // Find the KML file in KMZ
    const kmlContent = await kmlFile.async('string');

    const parser = new xml2js.Parser();
    const kmlJson = await parser.parseStringPromise(kmlContent);

    return extractCoordinatesFromKml(kmlJson);
  }

  function extractCoordinatesFromKml(kmlJson) {
    const coordinates = [];
    const folders = kmlJson.kml.Document[0].Folder || [];

    folders.forEach(folder => {
      const placemarks = folder.Placemark || [];

      placemarks.forEach((placemark, index) => {
        if (placemark.MultiGeometry && placemark.MultiGeometry[0].Polygon) {
          const polygons = placemark.MultiGeometry[0].Polygon;

          polygons.forEach(polygon => {
            const coordString = polygon.outerBoundaryIs[0].LinearRing[0].coordinates[0];
            const coords = coordString.trim().split(/\s+/).map(c => {
              const [lng, lat] = c.split(',').map(Number);
              return [lat, lng];
            });
            coordinates.push({ type: 'polygon', coords });
          });

        } else if (placemark.Point) {
          const [lng, lat] = placemark.Point[0].coordinates[0].split(',').map(Number);
          coordinates.push({ type: 'point', coords: [lat, lng] });
        } else {
          console.warn(`Unknown geometry at placemark index ${index}`, placemark);
        }
      });
    });

    return coordinates.length > 0 ? coordinates : null;
  }

  function removeAllLayers() {
    if (editableFGref) {
      const drawnItems = editableFGref._layers;

      Object.keys(drawnItems).forEach(layerid => {
        editableFGref.removeLayer(drawnItems[layerid]);
      });
    }
  }

  function onSetActive(place) {
    console.log(place);
  }

  function metersToRadian(meters){
    const earthRadiusInmeters = 6371000;
    return meters / earthRadiusInmeters;
  }

  function onFeatureGroupReady(reactFGref) {
    if (reactFGref) {
      editableFGref = reactFGref;
    }
  }

  function layerToFilter(layer) {
    let result = {};
    const { _mRadius, _leaflet_id, _latlng } = layer;

    if (_mRadius) {
      const { lat, lng } = _latlng;
      result = {
        type: 'Feature',
        properties: {},
        geometry: { type: 'Circle', coordinates: [lng, lat], radius: metersToRadian(_mRadius) }
      };
    } else {
      result = layer.toGeoJSON();
    }

    result.properties.leaflet_id = _leaflet_id;
    return result;
  }

  function handleCreated({ layer }) {
    const filter = layerToFilter(layer);

    if (geoFilters) {
      geoFilters.push(filter);
    } else {
      geoFilters = [filter];
    }

    onGeoFiltersChange(geoFilters);
  }

  function handleEdited({ layers }) {
    layers.eachLayer((layer) => {
      const { _leaflet_id } = layer;
      const previousFilterId = getFilterIndexById(_leaflet_id);

      geoFilters[previousFilterId] = layerToFilter(layer);
    });

    onGeoFiltersChange(geoFilters);
  }

  function handleDeleted({ layers }) {
    const indexesToDelete = [];

    layers.eachLayer((layer) => {
      const { _leaflet_id } = layer;
      indexesToDelete.push(getFilterIndexById(_leaflet_id));
    });

    indexesToDelete.sort(function(a,b){ return b - a; });

    for (let i = indexesToDelete.length -1; i >= 0; i--) {
      geoFilters.splice(indexesToDelete[i], 1);
    }

    onGeoFiltersChange(geoFilters);
  }

  function getFilterIndexById(leafletId) {
    for (let i = 0; i < geoFilters.length; i++) {
      if (geoFilters[i].properties.leaflet_id === leafletId) {
        return i;
      }
    }
    return -1;
  }

  async function handleKmzUpload(event) {
    setLoading(true);
    const file = event.target.files[0];

    if (file) {
      const coordinates = await parseKmzFile(file);
      setKmzCoordinates(coordinates);

      const newGeoFilters = coordinates.map((shape, index) => ({
        type: 'Feature',
        properties: { leaflet_id: `kmz-${index}` },
        geometry: shape.type === 'polygon'
          ? { type: 'Polygon', coordinates: [shape.coords.map(([lat, lng]) => [lng, lat])] }
          : { type: 'Point', coordinates: [shape.coords[1], shape.coords[0]] }
      }));

      onGeoFiltersChange(newGeoFilters);
      setLoading(false);
    }
  }

  function UploadControl() {
    const map = useMap();

    useEffect(() => {
      const controlDiv = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
      controlDiv.innerHTML = `
        <label style="cursor: pointer; padding: 5px; font-size: 12px;">
          📁 Upload KMZ
          <input type="file" accept=".kmz" style="display: none;" />
        </label>`;

      controlDiv.querySelector('input').addEventListener('change', handleKmzUpload);

      const control = L.control({ position: 'bottomright' });
      control.onAdd = () => controlDiv;
      control.addTo(map);

      return () => {
        control.remove();
      };
    }, [map]);
    return null;
  }

  return (<Spin spinning={loading} >
    <MapContainer
      center={center}
      zoom={DEFAULT_ZOOM}
      zoomControl={false}
      whenCreated={setMap}
      style={{ height: '70vh' }}
    >
      <ZoomControl position='topright'/>
      <TileLayer
        attribution={description}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {places && places.map(place =>
        <Marker
          key={place.os_id}
          position={[
            place ? place.latitude : 40.7055537,
            place? place.longitude : -74.0134436
          ]} onClick={() => onSetActive(place)}>
          <Popup>
            <PlaceTitle key={place.id} value={place}/>
          </Popup>
        </Marker>
      )}

      {kmzCoordinates.map((shape, index) => shape.type === 'polygon' ? <Polygon key={index} positions={shape.coords} /> : <Circle key={index} center={shape.coords} radius={200} /> )}

      <FeatureGroup ref={(reactFGref) => onFeatureGroupReady(reactFGref)}>
        <EditControl
          position='topright'
          onEdited={handleEdited}
          onCreated={handleCreated}
          onDeleted={handleDeleted}
          draw={{ polyline: false, marker: false, circlemarker: false, rectangle: true }}
        />
        <UploadControl />
      </FeatureGroup>
    </MapContainer>
  </Spin>);
}

Map.propTypes = {
  places: PropTypes.array.isRequired,
  geoFilters: PropTypes.array,
  onGeoFiltersChange: PropTypes.func.isRequired,
  selectedPlace: PropTypes.number,
  description: PropTypes.string,
};

export default React.memo(Map, (prevProps, nextProps) => {
  return (
    prevProps.selectedPlace === nextProps.selectedPlace &&
    arraysAreEqual(prevProps.places, nextProps.places) &&
    arraysAreEqual(prevProps.geoFilters, nextProps.geoFilters)
  );
});
