import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { notification, Col, Row, Card } from 'antd';
import qs from 'query-string';
import { saveAs } from 'file-saver';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

import { removeEmptyValues } from '../../helpers/common';
import apiPlaces from '../../api/apiPlaces';
import AuthModal from '../Common/AuthModal';
import Search from './Search';
import SearchResults from './SearchResults';
import Map from './Map/Map';
import { useDimensions } from '../../utils/hooks';

const StyledCol = styled(Col)`
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
`;
const StyledMapWrapper = styled.div`
  border-radius: 32px;
  border: 1px solid #D9D9D9;
  overflow: hidden;

  @media (max-width: 1350px) {
    display: none !important;
  }
`;

const PAGE_SIZE = 10;
const defaultParams = {};

export default function Places({ auth }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState({  ...defaultParams, ...qs.parse(location.search), limit: PAGE_SIZE });
  const { geoFilters, view, utm_source } = searchParams;

  if (utm_source) {
    localStorage.setItem('source', utm_source);
    delete searchParams.utm_source;
  }

  const { authenticated, profile = {} } = auth;
  // eslint-disable-next-line no-unused-vars
  const { emailVerified } = profile;
  const hideControls = view === 'true';

  const [authModalVisible, setAuthModalVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingEstimate, setLoadingEstimate] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(0);
  const [total, setTotal] = useState(null);
  const [searched, setSearched] = useState(true);

  const width = useDimensions();
  const slimMode = width < 700;
  const [searchSectionWidth, setSearchSectionWidth] = useState(width > 1350 ? (width > 1350 ? 14 : 18) : 100);
  const [mapSectionWidth, setMapSectionWidth] = useState(width > 1350 ? 8 :10);
  const skip = parseInt(searchParams.skip) || 0;
  const pageSize = parseInt(searchParams.limit) || 0;

  useEffect(() => {
    setSearchSectionWidth(width < 1550 ? (width > 1350 ? 17 : 100) : 14);
    setMapSectionWidth(width < 1550 ? (width > 1350 ? 7 : 12) : 10);
  }, [width]);

  useEffect(() => {
    document.title = 'Catalog of Local Businesses';

    fetchData(searchParams, true);
    onSearchParamsChange(searchParams);
  }, []);

  async function fetchData(params, fetchEstimate = true) {
    setLoading(true);
    setSelectedPlace(0);
    try {
      const data = await apiPlaces.fetchPlaces(params);
      setPlaces(data.data);
    } catch (error) {
      notification.error({ message: error.message });
    }
    setLoading(false);

    if (fetchEstimate) await fetchDataEstimate(params, { geoFilters });
  }

  async function fetchDataEstimate(params) {
    setLoadingEstimate(true);
    try {
      const data = await apiPlaces.fetchPlacesEstimate(params);
      setTotal(data.total);
    } catch (error) {
      notification.error({ message: error.message });
    }
    setLoadingEstimate(false);
  }

  async function onExport(resultType, limit) {
    setExporting(true);
    try {
      const data = await apiPlaces.exportPlaces({ ...searchParams, resultType, limit });
      saveAs(data, `places_${limit}.${resultType}`);
    } catch(error) {
      notification.error({ message: error.message });
    }
    setExporting(false);
  }

  function onSearchParamsChange(params) {
    const newParams = cloneDeep(params);
    removeEmptyValues(newParams);
    setSearchParams(searchParams => ({ ...searchParams, ...newParams }));

    if (!newParams.geoFilters) {
      navigate({ search: `?${qs.stringify(newParams)}` });
    } else {
      const shortParams = cloneDeep(newParams);
      delete shortParams.geoFilters;
      navigate({ search: `?${qs.stringify(shortParams)}` });
    }
  }

  function onGeoFiltersChange(geoFilters) {
    handleSearchParamsChange({ geoFilters, limit: searchParams.limit });
  }

  async function onSearch(params) {
    if ('skip' in params) delete params.skip; // remove skip when change params

    onSearchParamsChange(params);
    await fetchData(params);
  }

  async function onPagination({ current, pageSize }) {
    const newParams = { ...searchParams, skip: (current - 1) * pageSize };
    if (newParams.skip === 0) delete newParams.skip;
    if (newParams.skip > 90 && !authenticated && !authModalVisible) setAuthModalVisible(true);

    onSearchParamsChange(newParams);
    await fetchData(newParams, false);
  }

  function handleSearchParamsChange(value) {
    onSearchParamsChange({ ...searchParams, ...value });
    setSearched(false);
  }

  return <>
    <Card>
      <Search
        searched={searched}
        setSearched={setSearched}
        loading={loading}
        placesShowing={places ? places.length : 0}
        exporting={exporting}
        loadingTotal={loadingEstimate}
        searchParams={searchParams}
        total={total}
        onExport={onExport}
        onSearch={onSearch}
        onChange={onSearchParamsChange}
        hideControls={hideControls}
      />
    </Card>
    <Row>
      <StyledCol span={slimMode ? 24 : searchSectionWidth}>
        <SearchResults
          loading={loading}
          exporting={exporting}
          places={places}
          total={total}
          skip={skip}
          pageSize={pageSize}
          onPagination={onPagination}
          onExport={onExport}
          searchParams={searchParams}
          onSelect={setSelectedPlace}
          onSearchParamsChange={handleSearchParamsChange}
        />
      </StyledCol>
      <StyledCol span={slimMode ? 0 : mapSectionWidth}>
        {!slimMode &&
          <StyledMapWrapper>
            <Map
              description={`Showing results ${skip + 1}-${skip + pageSize}&nbsp;&nbsp;&nbsp;&nbsp;`}
              selectedPlace={selectedPlace}
              places={places}
              geoFilters={geoFilters}
              onGeoFiltersChange={onGeoFiltersChange}
              searchParams={searchParams}
            />
          </StyledMapWrapper>
        }
      </StyledCol>
    </Row>
    <AuthModal visible={authModalVisible} onClose={() => setAuthModalVisible(false)} />
  </>;
}

Places.propTypes = {
  auth: PropTypes.object.isRequired,
  authForwarded: PropTypes.object,
};
