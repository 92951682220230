import React from 'react';

import { Avatar, Carousel, Typography } from 'antd';
import { UserOutlined, FacebookFilled } from '@ant-design/icons';
import { SiTrustpilot } from 'react-icons/si';

const { Title, Paragraph } = Typography;

const trustPilotIcon = <SiTrustpilot color='#00b67a' />;

const testimonials = [
  {
    name: 'Taleb Tatari',
    position: 'NL',
    avatar: 'https://user-images.trustpilot.com/5ab8d7484de5666d340a83aa/73x73.png',
    testimonial: 'Great service and solution! Their service is amazing! I ran into a problem and my concern was addressed almost immediately and solved quickly.',
    link: 'https://www.trustpilot.com/reviews/66cccd9df30a097139df1c1d',
    icon: trustPilotIcon,
  },
  {
    name: 'Myles Oneil',
    position: '5D Media Marketing',
    avatar: 'https://scontent.fisb13-1.fna.fbcdn.net/v/t39.30808-1/321383623_1059625428766067_2565460535189615862_n.jpg?stp=cp0_dst-jpg_s80x80&_nc_cat=108&ccb=1-7&_nc_sid=0ecb9b&_nc_ohc=yut_9Pznl6UQ7kNvgFuB5JM&_nc_ht=scontent.fisb13-1.fna&oh=00_AYCwhQLOE81iQSTfLQftavh0je0t7NEiiO9kPbd15-Tfyw&oe=66E60015',
    testimonial: 'Great product, Allen from the support team, did an awesome job at helping me when I had an issue and it was solved lightening fast!',
    link: 'https://www.facebook.com/share/p/18Wv7VKgzp/',
    icon: <FacebookFilled />,
  },
  {
    name: 'Sam Watts',
    position: 'US',
    avatar: 'https://user-images.trustpilot.com/65cfa8fdfa121300126c3fbc/73x73.png',
    testimonial: 'Services are unmatched for the quality and price! I have been consistently using their services, and it is not only a money saver but the features are amazing for lead generation.',
    link: 'https://www.trustpilot.com/reviews/65cfa9078e797d146832213b',
    icon: trustPilotIcon,
  },
  {
    name: 'Jimmy Mardis',
    position: 'US',
    avatar: 'https://user-images.trustpilot.com/660e05fcfa77850012cadb23/73x73.png',
    testimonial: 'I\'m no computer expert so it has to be easy to use, and it is! It\'s much cheaper than anything else I can find.',
    link: 'https://www.trustpilot.com/reviews/660e0608c91dadd49c85c996',
    icon: trustPilotIcon,
  },
  {
    name: 'Alex Siderius',
    position: 'NL',
    avatar: 'https://user-images.trustpilot.com/62b44fbf14eb6f001480491f/73x73.png',
    testimonial: 'Great software and great customer service! Even though I have a Lifetime deal, they help me out whenever I have a question or run into a problem.',
    link: 'https://www.trustpilot.com/reviews/65cf9a6f3bb2ce45ef3d56ea',
    icon: trustPilotIcon,
  },
  {
    name: 'Dr. Ndi Amama',
    position: 'US',
    avatar: 'https://user-images.trustpilot.com/664528355399f24f7e6802be/73x73.png',
    testimonial: 'Excellent and timely customer service! Great platform. Highly recommend.',
    link: 'https://www.trustpilot.com/reviews/664528475c41ea39842a7d43',
    icon: trustPilotIcon,
  },
  {
    name: 'Ayushi Garg',
    position: 'IN',
    avatar: 'https://user-images.trustpilot.com/66731d4de7c5973250039cf6/73x73.png',
    testimonial: 'Great Database for Lead Gen.',
    link: 'https://www.facebook.com/share/p/19JPfjWxBT/',
    icon: <FacebookFilled />,
  },
];

export default function Testimonials() {
  return <>
    <Carousel
      autoplay
      autoplaySpeed={6000}
      className='testimonial_section'
    >
      {testimonials.map((testimonial) => (
        <div style={{ textAlign: 'center' }} key={testimonial.name}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <a href={testimonial.link} target='_blank' rel='noopener noreferrer'>
              <Avatar
                size={64}
                icon={<UserOutlined />}
                src={testimonial.avatar}
                style={{ marginBottom: '12px', marginTop: '4px' }}
              />
            </a>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Title level={4} style={{ marginBottom: 0, display: 'inline-block' }}>{testimonial.name}</Title>
            <a href={testimonial.link} target='_blank' rel='noopener noreferrer' style={{ marginLeft: '8px' }}>{testimonial.icon}</a>
            <Paragraph italic style={{ marginTop: '4px' }}>{testimonial.position}</Paragraph>
          </div>
          <Paragraph italic style={{ marginTop: '12px', marginBottom: '32px', marginLeft: '4px', textAlign: 'center' }}>{testimonial.testimonial}</Paragraph>
        </div>
      ))}
    </Carousel>
  </>;
}
