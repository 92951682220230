import apiClient from './apiClient';

export default {
  fetchPlaces: (params) => {
    const { geoFilters, ...otherParams } = params;
    return apiClient.post('/places/', { geoFilters }, { params: otherParams });
  },
  fetchCompanies: (params) => apiClient.get('/companies/', { params }),
  fetchCompaniesIndustrySelections: () => apiClient.get('/selections/companies/industry/'),
  fetchPlacesEstimate: (params) => {
    const { geoFilters, ...otherParams } = params;
    return apiClient.post('/estimate/places', { geoFilters }, { params: otherParams });
  },
  fetchPlacesOptions: (column, params) => apiClient.get(`/options/places/${column}`, { params }),
  fetchCompaniesEstimate: (params) => apiClient.get('/estimate/companies', { params }),
  fetchCompaniesOptions: (column, params) => apiClient.get(`/options/companies/${column}`, { params }),
  exportPlaces: (params) => apiClient.get('/places/', { responseType: 'blob', params }),
  exportCompanies: (params) => apiClient.get('/companies/', { responseType: 'blob', params }),
  fetchPlace: (id) => apiClient.get(`/places/${id}/`),
};
