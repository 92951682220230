import React from 'react';
import styled from 'styled-components';
import AppLogo from '../../assets/app/logo.png';
import ProfileHeader from './ProfileHeader';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 16px 0;
`;

const StyledLogo = styled.img`
  width: 150px;
`;

export default function Header({ auth }) {
  const shouldHideLogo = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.has('hideLogo');
  };

  return (
    <>
      {!shouldHideLogo() && (
        <StyledDiv className='desktop'>
          <StyledLogo src={AppLogo} alt='targetron logo' />
          <ProfileHeader auth={auth} />
        </StyledDiv>
      )}
    </>
  );
}

Header.propTypes = {
  auth: PropTypes.object.isRequired,
};
