import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

export default function ExportOneButton({ onClick }) {

  const ExportButton = styled(Button)`
    position: absolute;
    bottom: 25px;
    right: 25px;
  `;

  return (
    <Tooltip title='Download one item'>
      <ExportButton
        type='link'
        shape='circle'
        title='Download one item'
        icon={<DownloadOutlined />}
        onClick={onClick}
      />
    </Tooltip>
  );
}

ExportOneButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
