import React, { useState } from 'react';
import queryString from 'query-string';
import { notification, Form, Card, Button, Popconfirm, Select, InputNumber } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import apiPayment from '../../../../api/apiPayment';

const { Option } = Select;
const { Meta } = Card;

const MIN_AMOUNT = 25;

export default function MakeCryptoPayment() {
  const navigate = useNavigate();
  const location = useLocation();
  const { amount: queryAmount } = queryString.parse(location.search);
  const [currency, setCurrency] = useState('btc');
  const [amount, setAmount] = useState(Math.max(Number(queryAmount) || 100, MIN_AMOUNT));
  const [loading, setLoading] = useState(false);
  const formatedCurrency = currency.toLocaleUpperCase();

  function handleSetAmount(value) {
    setAmount(parseInt(value) || MIN_AMOUNT);
  }

  async function createCryptoPayment() {
    setLoading(true);
    try {
      const data = await apiPayment.createCryptoPayment(amount, currency);
      const { payment_id } = data;
      navigate(`/profile/crypto-payment/${payment_id}`);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card style={{ width: 450 }}>
      <Meta
        title='Add Credits With Crypto'
        description='Deposit funds to your account balance with cryptocurrency and receive a 3% bonus from your payment.'
      />
      <br/>
      <Form name='basic' layout='vertical'>
        <Form.Item label='Currency'>
          <Select style={{ width: 125 }} value={currency} onChange={setCurrency}>
            <Option value='btc'>BTC</Option>
            <Option value='eth'>ETH</Option>
            <Option value='usdc'>USDC</Option>
            <Option value='usdttrc20'>USDTTRC20</Option>
            <Option value='usdtsol'>USDTSOL</Option>
          </Select>
        </Form.Item>
        <Form.Item label='Amount USD'>
          <InputNumber
            formatter={value => `$ ${value}`}
            value={amount}
            min={MIN_AMOUNT}
            max={5000}
            step={10}
            onChange={handleSetAmount}
          />
        </Form.Item>
        <Form.Item>
          <Popconfirm
            title={`Create $${amount} payment with ${formatedCurrency}?`}
            onConfirm={createCryptoPayment}
          >
            <Button loading={loading} title={`Create ${formatedCurrency} Address And Pay`} type='primary'>
              Create {formatedCurrency} address and Pay
            </Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    </Card>
  );
}
