import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Collapse, Tag, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import SelectType from '../Common/SelectType';
import SelectNaics from '../Common/SelectNaics';
import SelectCountry from '../Common/SelectCountry';
import SelectState from '../Common/SelectState';
import SelectCityOrTown from '../Common/SelectCityOrTown';
import ExportButton from '../Common/ExportButton';
import SearchStatus from '../Common/SearchStatus';

const StyledCollapse = styled(Collapse)`
  margin-bottom: -20px;
  font-size: 13px;
`;
const advancedItemStyle = { width: 200 };

const { Panel } = Collapse;
const { Option, OptGroup } = Select;

export default function Search({ searchParams, onChange, size, loading, onSearch, exporting, total, loadingTotal, hideControls, selectableTypes, loadingSelections, searched, setSearched }) {
  const { cc, state, city, sic, type, domain, name, generator, _id, size: companySize } = searchParams;
  const exportDisabled = loading || loadingTotal || total === 0;
  const advancedParametersCount = [name, domain, generator, sic, _id].filter(x => x).length;

  const [active, setActive] = useState(null);

  function onSearchHandle() {
    onSearch({ ...searchParams });
    setSearched(true);
    setActive(null);
  }

  function onChangeHandle(value) {
    onChange(value);
    setSearched(false);
  }

  function onCountryChange(countryCode) {
    delete searchParams.state;
    delete searchParams.city;
    onChangeHandle({ ...searchParams, cc: countryCode });
  }

  function onStateChange(newState) {
    delete searchParams.city;
    onChangeHandle({ ...searchParams, state: newState });
  }

  function onCollapseChange() {
    setActive(active ? null : 'advanced');
  }

  return (
    <>
      <Form name='search-form' layout='inline' className='search-form'>
        {!hideControls && <>
          <Form.Item>
            <SelectType
              size={size}
              value={type}
              placeholder='Industry'
              onChange={(v) => onChangeHandle({ ...searchParams, type: v })}
              types={selectableTypes}
              loading={loadingSelections}
            />
          </Form.Item>
          <Form.Item>
            <Input.Group compact>
              <Form.Item noStyle>
                <SelectCountry
                  value={cc}
                  onChange={onCountryChange}
                />
              </Form.Item>
              {cc === 'US' &&
                <Form.Item noStyle>
                  <SelectState
                    placeholder='State'
                    value={state}
                    onChange={onStateChange}
                  />
                </Form.Item>
              }
              <Form.Item>
                <SelectCityOrTown
                  filters={{ cc, state }}
                  countryCode={cc}
                  state={state}
                  value={city}
                  onChange={(value) => onChangeHandle({ ...searchParams, city: value })}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item>
            <Button
              htmlType='submit'
              title='Make a search'
              className='search'
              type={searched ? 'default' : 'primary'}
              loading={loading}
              icon={<SearchOutlined/>}
              onClick={onSearchHandle}
            >Search</Button>
          </Form.Item>
        </>}

        <Form.Item>
          <ExportButton
            productName='Companies And Executives'
            units='businesses'
            type={searched ? 'primary' : 'default'}
            totalResults={total}
            loading={exporting || loadingTotal}
            searchParams={searchParams}
            disabled={exportDisabled}
          />
        </Form.Item>
      </Form>

      {!hideControls && <>
        <StyledCollapse ghost activeKey={active} onChange={onCollapseChange}>
          <Panel
            key='advanced'
            header={'Advanced filters' + (advancedParametersCount > 0 ? ` (${advancedParametersCount})` : '')}
          >
            <Form name='advanced-form' size='small' labelCol={{ span: 2 }}>
              <Form.Item label='Name'>
                <Input
                  size={size}
                  style={advancedItemStyle}
                  placeholder='Marriott'
                  value={name}
                  onChange={({ target: { value } }) => onChangeHandle({ ...searchParams, name: value })}
                />
              </Form.Item>
              <Form.Item label='Domain'>
                <Input
                  size={size}
                  style={advancedItemStyle}
                  placeholder='bitcoin.org'
                  value={domain}
                  onChange={({ target: { value } }) => onChangeHandle({ ...searchParams, domain: value })}
                />
              </Form.Item>
              <Form.Item label='Generator'>
                <Input
                  size={size}
                  style={advancedItemStyle}
                  placeholder='Wordpress'
                  value={generator}
                  onChange={({ target: { value } }) => onChangeHandle({ ...searchParams, generator: value })}
                />
              </Form.Item>
              <Form.Item label='Employees'>
                <Select
                  allowClear
                  placeholder='Employees count'
                  value={companySize}
                  style={advancedItemStyle}
                  onChange={(v) => onChangeHandle({ ...searchParams, size: v })}
                >
                  <OptGroup label='Range'>
                    <Option key='1' value='1'>1 - 10</Option>
                    <Option key='10' value='10'>10 - 100</Option>
                    <Option key='100' value='100'>100 - 1 000</Option>
                    <Option key='1000' value='1000'>1000 - 10 000</Option>
                  </OptGroup>
                  <OptGroup label='Greater or equal'>
                    <Option key='10+' value='10+'>10 and more</Option>
                    <Option key='50+' value='50+'>50 and more</Option>
                    <Option key='100+' value='100+'>100 and more</Option>
                    <Option key='250+' value='250+'>250 and more</Option>
                    <Option key='500+' value='500+'>500 and more</Option>
                    <Option key='1000+' value='1000+'>1,000 and more</Option>
                    <Option key='10000+' value='10000+'>10,000 and more</Option>
                  </OptGroup>
                  <OptGroup label='Less or equal'>
                    <Option key='10-' value='10-'>up to 10</Option>
                    <Option key='50-' value='50-'>up to 50</Option>
                    <Option key='100-' value='100-'>up to 100</Option>
                    <Option key='250-' value='250-'>up to 250</Option>
                    <Option key='500-' value='500-'>up to 500</Option>
                    <Option key='1000-' value='1000-'>up to 1,000</Option>
                    <Option key='10000-' value='10000-'>up to 10,000</Option>
                  </OptGroup>
                </Select>
              </Form.Item>
              <Form.Item label='SIC'>
                <SelectNaics
                  notFoundContent='Enter SIC codes'
                  placeholder='242100'
                  size={size}
                  style={advancedItemStyle}
                  value={sic}
                  onChange={(v) => onChangeHandle({ ...searchParams, sic: v })}
                />
              </Form.Item>
              {_id && <Form.Item label='ID'>
                <Tag closable onClose={() => onChangeHandle({ ...searchParams, _id: undefined })}>{_id}</Tag>
              </Form.Item>}
            </Form>
          </Panel>
        </StyledCollapse>
      </>}
      <SearchStatus total={total} loading={loading} loadingTotal={loadingTotal} searched={searched} />
    </>
  );
}

Search.propTypes = {
  searchParams: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  size: PropTypes.string,
  loading: PropTypes.bool,
  onExport: PropTypes.func.isRequired,
  exporting: PropTypes.bool,
  total: PropTypes.number,
  loadingTotal: PropTypes.bool,
  hideControls: PropTypes.bool,
  selectableTypes: PropTypes.array,
  loadingSelections: PropTypes.bool,
  setSearched: PropTypes.func,
  searched: PropTypes.bool,
};
