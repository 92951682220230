import React from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'antd';
import { Trans } from 'react-i18next';

import AddCredits from './AddCredits/AddCredits';

export default function Balance({ loading, profile, onPaymentMade, emailVerified }) {
  const { balance, coupon = {}, risk_level = 0 } = profile;
  const { duration_in_months, duration } = coupon;
  const couponDuration = duration_in_months ? `for ${duration_in_months} months` : duration;
  const { name: couponName } = coupon;

  return <>
    <Statistic title='Your remaining credit' value={balance} precision={2} prefix='$' />
    <br/>
    <br/>
    <h3>Top Up</h3>
    <AddCredits loading={loading} callback={onPaymentMade} riskLevel={risk_level} emailVerified={emailVerified}/>
    <br/>
    <br/>
    {balance === 0 && <>
      <p className='help-p2'>
        * <Trans i18nKey='description.usingCredits'>Use your deposit later, with no expiration</Trans>.
      </p>
    </>}
    <br/>

    {couponName && <>
      <Statistic title='Special Offer' value={couponName} suffix={couponDuration}/><br/><br/>
    </>}
  </>;
}

Balance.propTypes = {
  loading: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  onPaymentMade: PropTypes.func.isRequired,
  emailVerified: PropTypes.bool,
};
