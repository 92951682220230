import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export default function Login({ auth }) {
  const location = useLocation();
  const { search } = location;
  const { redirect, utm_source } = queryString.parse(search);

  if (redirect) localStorage.setItem('redirect', redirect);
  if (utm_source) localStorage.setItem('source', utm_source);

  auth.login();

  return <h5>Loading...</h5>;
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
};
