import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';


export default function Callback({ handleAuthentication }) {
  const location = useLocation();

  useEffect(() => {
    handleAuthentication({ location });
  }, [handleAuthentication]);

  return (
    <h5>Loading...</h5>
  );
}

Callback.propTypes = {
  handleAuthentication: PropTypes.func.isRequired,
};