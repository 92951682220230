import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Empty, notification } from 'antd';
import { isEmpty } from 'lodash';

import { toArray } from '../../utils/helper';
import apiPlaces from '../../api/apiPlaces';
import { usePrevious } from '../../utils/hooks';

const { Option, OptGroup } = Select;

export default function AttributesFilter({ value, onChange, filters }) {
  const arrayValue = toArray(value);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const previousFilters = usePrevious(filters);

  useEffect(() => {
    if (isEmpty(filters)) {
      setData({});
    } else {
      const { t: type  } = filters;
      const { t: previousType } = previousFilters || {};

      if (isEmpty(type)) {
        setData({});
      } else {
        if (type !== previousType) {
          fetchData();
        }
      }
    }
  }, [filters]);

  async function fetchData() {
    setLoading(true);
    try {
      const data = await apiPlaces.fetchPlacesOptions('about', { type: filters.type, cc: filters.cc });
      setData(data);
    } catch (error) {
      notification.error({ message: error.message });
    }
    setLoading(false);
  }

  return (
    <Select
      showSearch
      allowClear
      mode='multiple'
      placeholder='Attributes'
      loading={loading}
      style={{ width: 300 }}
      value={arrayValue}
      onChange={onChange}
      notFoundContent={<Empty description='Please select at least one category first'></Empty>}
    >
      {Object.keys(data).map(key => <>
        <OptGroup label={key} key={key}>
          {Object.keys(data[key]).map(v => <Option key={`${key}-${v}`} value={`${key}.${v}`}>{v}</Option>)}
        </OptGroup>
      </>)}
    </Select>
  );
}

AttributesFilter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  countryCode: PropTypes.string,
  filters: PropTypes.object.isRequired,
};
