import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Avatar, Typography } from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { MdCorporateFare, MdOutlineLocationOn } from 'react-icons/md';

import Rating from './Rating';
import CheckoutModal from '../../Common/CheckoutModal/CheckoutModal';
import Address from '../../Common/Address';
import ExportOneButton from '../../Common/ExportOneButton';
import HidedValue from '../../Common/HidedValue';
import Categories from '../../Common/Categories';
import { handleOnCopy } from '../../../utils/helper';
import PlaceTitle from './PlaceTitle';
import { FiLink, FiMail, FiPhone } from 'react-icons/fi';

import FacebookIcon from '../../../assets/icons/facebook_icon.png';
import InstagramIcon from '../../../assets/icons/instagram_icon.png';
import LinkedInIcon from '../../../assets/icons/linkedin_icon.png';
import TwitterIcon from '../../../assets/icons/twitter_icon.png';
import TickTokIcon from '../../../assets/icons/tiktok_icon.png';
import { HiUserGroup } from 'react-icons/hi';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import { useDimensions } from '../../../utils/hooks';

let formatter = Intl.NumberFormat('en', { notation: 'compact' });

const { Link } = Typography;

const StyledFlexWrapper = styled.div`
  display: flex;
  gap: 25px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const StyledCard = styled.div`
  padding: 20px;
  /* display: flex; */
  width: 100%;
  box-shadow: 0px 0px 20px rgba(127, 127, 127, 0.25);
  border-radius: 28px;
  border-color: #D9D9D9;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  height: fit-content;

  @media (max-width: 900px) {
    flex-direction: column;
    font-size: 14px;
  }
`;

const ContactDataContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 16px;
  margin: 5px 0px 5px 0px;

  @media (max-width: 900px) {
    font-size: 20px;
  }
`;

const LeftDiv = styled.div`
  display: flex;
  float: left;
  font-size: 12px;
  gap: 15px;

  @media (max-width: 900px) {
    justify-content: space-between;
  }
`;

const RightDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 12px;

  @media (max-width: 900px) {
    margin-left: 0px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 900px) {
    align-items: flex-start;
  }
`;

const Image = styled.img`
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  height: 25px;
  width: 25px;
  user-select: none;
  pointer-events: none;
  margin-left: 2px;
  margin-right: 2px;
`;

const customAvatarStyles = { borderRadius: 10 };

const FULL_DATA_TIP = 'Click the "Export" button to get this data.';

export default function PlaceCard({ value, onSearchParamsChange }) {
  const {
    os_id,
    display_name, photo, types, located_in, located_os_id,
    site, phone, phone_type, linkedin, facebook, twitter, instagram, ticktok, rating_history,
    email_1, email_2, email_3, email_1_title, email_1_first_name,
    price_range, corp_revenue, corp_employees, corp_founded_year, corp_is_public,
  } = value;
  const [modalVisible, setModalVisible] = useState(false);

  const domain = site ? site.replace('www.','').replace('http://','').replace('https://','').split('/')[0] : null;

  function hadnleLocatedInClick() {
    onSearchParamsChange({ located: located_os_id, type: null });
  }

  function onExport() {
    setModalVisible(!modalVisible);
  }

  const width = useDimensions();

  return (
    <StyledCard bordered={true} onCopy={handleOnCopy}>

      <StyledFlexWrapper className='card-wrapper'>
        <LeftDiv>
          <Avatar shape='square' size={130} src={photo} icon={<ShopOutlined/>} style={customAvatarStyles}/><br/>

          <ButtonContainer className='mobile'>
            {width > 570 && <Rating value={value} />} {price_range && price_range}
            <Tooltip title='The employee count and revenue could be the individual unit or corporate info'>
              {corp_founded_year && <ContactDataContainer><MdCorporateFare /> <strong>Founded:</strong>{corp_founded_year}</ContactDataContainer>}
              {corp_employees && <ContactDataContainer><HiUserGroup /> <strong>Employees:</strong> {formatter.format(corp_employees)}<br/></ContactDataContainer>}
              {corp_revenue && <ContactDataContainer><RiMoneyDollarCircleLine /> <strong>Revenue:</strong> {formatter.format(corp_revenue)}<br/></ContactDataContainer>}
              {(corp_is_public === true || corp_is_public === false) && <ContactDataContainer><MdCorporateFare/> <strong>Type:</strong> {corp_is_public ? 'Public' : 'Private'}<br/></ContactDataContainer>}
            </Tooltip>
          </ButtonContainer>
        </LeftDiv>

        <RightDiv>
          <div>
            <TitleContainer>
              <PlaceTitle value={value} onExport={onExport}/>
              <Categories value={types}/>
            </TitleContainer>
            <div className='desktop'>{width < 1040 && <Rating value={value} />}{price_range && price_range}</div>
            <div className='mobile' style={{ marginTop: 10 }}>{width < 570 && <Rating value={value} />}{price_range && price_range}</div>


            <Tooltip
              title={<>
                {rating_history && <>history:<br/>{rating_history.map((el, i) => <div key={`${el}-${i}`}>{el}<br/></div>)}</>}
              </>}>
            </Tooltip>
            <ContactDataContainer><MdOutlineLocationOn size={23} />&nbsp;<Address value={value} /></ContactDataContainer>
            {located_in && <ContactDataContainer><MdOutlineLocationOn size={22} />&nbsp;<Link onClick={hadnleLocatedInClick}>{located_in}</Link></ContactDataContainer>}
            {domain && <ContactDataContainer><FiLink size={22}/> &nbsp;<HidedValue value={`https://${domain}`}/></ContactDataContainer>}
            {phone && <ContactDataContainer><FiPhone size={22}/> &nbsp;<HidedValue value={phone}/> {phone_type && <>({phone_type})</>}</ContactDataContainer>}
            <Tooltip title={FULL_DATA_TIP}>
              {linkedin && <Image src={LinkedInIcon} />} {facebook && <Image src={FacebookIcon} />} {twitter && <Image src={TwitterIcon} />} {instagram && <Image src={InstagramIcon} />} {ticktok && <Image src={TickTokIcon} />}
            </Tooltip>
          </div>

          <ButtonContainer className='desktop'>
            {width > 1040 && <Rating value={value} />}{price_range && price_range}
            <Tooltip title='The employee count and revenue could be the individual unit or corporate info'>
              {corp_founded_year && <ContactDataContainer><MdCorporateFare /> <strong>Founded:</strong>{corp_founded_year}</ContactDataContainer>}
              {corp_employees && <ContactDataContainer><HiUserGroup /> <strong>Employees:</strong> {formatter.format(corp_employees)}<br/></ContactDataContainer>}
              {corp_revenue && <ContactDataContainer><RiMoneyDollarCircleLine /> <strong>Revenue:</strong> {formatter.format(corp_revenue)}<br/></ContactDataContainer>}
              {(corp_is_public === true || corp_is_public === false) && <ContactDataContainer><MdCorporateFare/> <strong>Type:</strong> {corp_is_public ? 'Public' : 'Private'}<br/></ContactDataContainer>}
            </Tooltip>
            <ExportOneButton
              title={`Export ${display_name} data`}
              onClick={onExport}
            />
          </ButtonContainer>
        </RightDiv>
      </StyledFlexWrapper>

      {email_1 && <ContactDataContainer className='desktop'><FiMail size={22}/> &nbsp;<HidedValue value={email_1}/>{email_1_title && <> ({email_1_title}: <HidedValue value={email_1_first_name}/>)</>} {email_2 && `+ ${email_3 ? '2' : '1'} more`}<br/></ContactDataContainer>}
      {email_1 && <ContactDataContainer className='mobile'><FiMail size={22}/> &nbsp;<HidedValue value={email_1}/>{email_2 && `+ ${email_3 ? '2' : '1'} more`}<br/></ContactDataContainer>}

      <CheckoutModal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        searchParams={{ os_id }}
        totalResults={1}
        prelaodProfile={false}
        productName='Places'
        units='rows'
      />
    </StyledCard>
  );
}

PlaceCard.propTypes = {
  value: PropTypes.object.isRequired,
  onSearchParamsChange: PropTypes.func.isRequired,
};
